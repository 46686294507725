<template>
  <main class="container pb-16 pb-lg-32 px-lg-32">
    <div class="bg-white rounded-8">
      <div class="p-16 border-bottom">
        <div class="row gx-16 mb-8">
          <div class="col-sm-3 col-xl-4">
            <GradeSelectField label="Kelas" v-model="filter.grade_id" />
          </div>
          <div class="col-sm-3 col-xl-4 mt-16 mt-sm-0">
            <SubjectSelectField label="Pelajaran" v-model="filter.subject_id" />
          </div>
          <div class="col-sm-6 col-xl-4 mt-16 mt-sm-0">
            <DateField
              label="Tanggal"
              v-model="filter.created_at"
              :vue2Datepicker="{
                defaultValue: defaultFilterDate,
                disabledDate: notAfterToday,
                format: 'YYYY-MM-DD',
                range: true,
              }"
            />
          </div>
        </div>
      </div>

      <div class="text-sm-center p-16" v-if="!filterSelected">
        Silakan pilih kelas, pelajaran dan tanggal terlebih dahulu.
      </div>

      <div class="is-loading is-loading-primary p-16" v-else-if="isFetching">
        &nbsp;
      </div>

      <div class="table-responsive" v-else-if="report">
        <table class="table align-middle">
          <thead>
            <tr>
              <th>Siswa</th>
              <th>NISN</th>
              <th
                class="title text-center"
                v-for="task of report.tasks"
                v-tooltip="task.title"
                :key="task.id"
              >
                {{ dayjs(task.created_at).format('D') }}
                <div class="fs-11 fw-normal text-muted text-uppercase">
                  {{ dayjs(task.created_at).format('MMM') }}
                </div>
              </th>
              <th class="text-center">Rata-Rata</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="student of report.students" :key="student.nisn">
              <td>{{ student.user.name }}</td>
              <td>{{ student.nisn }}</td>
              <td
                class="text-center"
                v-for="task of report.tasks"
                :key="task.id"
              >
                {{ getTaskScore(student, task) }}
              </td>
              <td class="text-center">{{ student.scores_avg }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </main>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import api from '../../../api';
import dayjs from '../../../bootstrap/dayjs';

import DateField from '../../../components/form/DateField.vue';
import GradeSelectField from '../../../components/grade/GradeSelectField.vue';
import SubjectSelectField from '../../../components/subject/SubjectSelectField.vue';

export default {
  components: {
    DateField,
    GradeSelectField,
    SubjectSelectField,
  },

  data() {
    return {
      filter: this.parseFilter(cloneDeep(this.$route.query.filter || {})),
      isFetching: false,
      report: null,
    };
  },

  computed: {
    defaultFilterDate() {
      return [dayjs().subtract(1, 'month').toDate(), new Date()];
    },

    filterSelected() {
      return !['grade_id', 'subject_id', 'created_at'].some(
        (filter) => !this.filter[filter]
      );
    },
  },

  methods: {
    dayjs,

    async fetch() {
      if (!this.filterSelected) return;

      try {
        this.isFetching = true;
        this.report = await api
          .get('v1/reports/tasks', { params: { filter: this.filter } })
          .then(({ data }) => data.data);
      } catch (error) {
        this.$alert.requestError(error);
      } finally {
        this.isFetching = false;
      }
    },

    notAfterToday(date) {
      return date > dayjs().endOf('day').toDate();
    },

    parseFilter(filter) {
      return Object.entries(filter).reduce((result, [key, value]) => {
        switch (key) {
          case 'created_at':
            result[key] = value && value.map((date) => new Date(date));
            break;
          case 'grade_id':
          case 'subject_id':
            result[key] = Number(value) || null;
            break;
          default:
            result[key] = value;
            break;
        }
        return result;
      }, {});
    },

    prepareFilter(filter) {
      return Object.entries(filter).reduce((result, [key, value]) => {
        switch (key) {
          case 'created_at': {
            if (value) {
              value[1] = dayjs(value[1]).endOf('day').toDate();
              result[key] = value;
            }

            break;
          }
          default:
            result[key] = value;
            break;
        }

        return result;
      }, {});
    },

    getTaskScore(student, task) {
      const answer = find(student.answers, ['task_id', task.id]);
      return answer ? answer.score : null;
    },
  },

  metaInfo: {
    title: 'Rekap Tugas',
  },

  watch: {
    filter: {
      handler(filter) {
        const query = {
          ...this.$route.query,
          filter: this.prepareFilter(filter),
        };

        this.$router.replace({ query });
        this.fetch();
      },
      deep: true,
    },

    filterSelected(filterSelected) {
      if (!filterSelected) {
        this.report = null;
      }
    },
  },

  beforeMount() {
    this.fetch();
  },
};
</script>

<style lang="scss" scoped>
.title {
  max-width: rem(128);
}
</style>
